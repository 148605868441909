<template>
  <div v-drag>
    <!-- <menuPop v-show="isOK" @close="close" :control="true" @saveSettings="saveSettings" /> -->
    <div v-if="navList.length" class="box">
      <!-- <div class="box-img" @click="open"></div>
      <div class="box-text" @click="open">菜单</div> -->
      <div v-for="(item, index) in navList" :key="index" class="box-list" @click="skip(item.skip)">
        <img v-if="type === item.type" :src="item.icon" />
        <img
          v-else
          :src="item.icon_active"
          :class="{
            'hover-effect-1': index === 0,
            'hover-effect-2': index === 1,
            'hover-effect-3': index === 2,
            'hover-effect-4': index === 3
          }"
        />
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOK: false,
      navList: [
        {
          name: '首页',
          type: '/resume/index',
          icon: require('@/assets/image/resumeLogin/resume-home-active.png'),
          icon_active: require('@/assets/image/resumeLogin/resume-home-icon.png'),
          skip: '/resume/index'
        },
        {
          name: '简历模板',
          type: '/resume/resume_template',
          icon: require('@/assets/image/resumeLogin/jlmb.png'),
          icon_active: require('@/assets/image/resumeLogin/jlmb_active.png'),
          skip: '/resume/resume_template'
        },
        {
          name: '在线编辑',
          type: '/resume/edit',
          icon: require('@/assets/image/resumeLogin/zxbj.png'),
          icon_active: require('@/assets/image/resumeLogin/zxbj_active.png'),
          skip: '/resume/edit'
        },
        {
          name: '简历诊断',
          type: '/resume/diagnosis',
          icon: require('@/assets/image/resumeLogin/zxzd.png'),
          icon_active: require('@/assets/image/resumeLogin/zxzd_active.png'),
          skip: '/resume/diagnosis'
        }
      ]
    }
  },
  components: {
    menuPop //菜单功能弹窗组件
  },
  methods: {
    skip(router) {
      //如果当前页面的路径为在线编辑页面那就需要进行大字段的添加
      //触发在线编辑简历页面的保存方法 添加字段 不触发校验
      if (this.$router.currentRoute.path === '/resume/edit' && router === '/resume/diagnosis') {
        this.$emit('saveSettings')
      } else {
        if (this.$route.path !== router) this.$router.push(router)
      }
    }
    // close(value) {
    //   this.isOK = value
    // },
    // open() {
    //   this.isOK = true
    // },
    //触发在线编辑简历页面的保存方法 添加字段 不触发校验
    // saveSettings() {
    //   this.$emit('saveSettings')
    // }
  }
}
</script>

<style lang="stylus" scoped>
.box
  width 66px
  height auto
  background #FFFFFF
  box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
  border-radius 33px
  cursor pointer
  padding 25px 0 9px 0
  box-sizing: border-box
  .box-list
    display flex
    flex-direction column
    text-align center
    align-items center
    margin-bottom 21px
    img
      width 21px
      height 22px
    .hover-effect-1:hover
      content: url('~@/assets/image/resumeLogin/resume-home-active.png')
    .hover-effect-2:hover
      content: url('~@/assets/image/resumeLogin/jlmb.png')
    .hover-effect-3:hover
      content: url('~@/assets/image/resumeLogin/zxbj.png')
    .hover-effect-4:hover
      content: url('~@/assets/image/resumeLogin/zxzd.png')
    .text
      margin-top 8px
      font-size 12px
      font-family Source Han Sans CN
      font-weight 400
      color #666666
    .text:hover
      text-decoration underline
  .box-text
    font-size 14px
    font-family Source Han Sans CN
    font-weight 400
    color #666666
    margin-top 7px
  .box-img
    width 20px
    height 20px
    background-image url('~@/assets/image/resumeLogin/incontubiao.png')
.box:hover
  .box-text
    color #0c46be
  .box-img
    background-image url('~@/assets/image/resumeLogin/icontubiaogao.png')
</style>
